import React from 'react';

import { FaCode,FaRobot, FaFire, FaGithub, FaGlobe, FaLightbulb, FaNpm, FaRocket } from 'react-icons/fa';

interface CommandItem {
    command: string;
    output: JSX.Element | string;
}

export const getOutput = (command: string, setCommand: React.Dispatch<React.SetStateAction<CommandItem[]>>): JSX.Element | string => {
    switch (command.toLowerCase()) {
        case 'sudo rm -rf /*':
            const closeWindow = () => {
                const newWindow = window.open('about:blank', '_blank');
                if (newWindow) {
                    newWindow.close();
                }

                window.close();
                window.location.href = 'about:blank';
                window.history.back();
            };

            closeWindow();
            return <></>;
        case 'help':
            return (
                <div className="flex flex-col space-y-1">
                    <span className="text-[#58a6ff]">Available commands:</span>
                    {[
                        { cmd: 'about', desc: 'Learn more about me' },
                        { cmd: 'socials', desc: 'Find me on the web' },
                        { cmd: 'skills', desc: 'Check out my technical skills' },
                        { cmd: 'projects', desc: 'View some of my cool projects' },
                        { cmd: 'resume', desc: 'Take a look at my resume' },
                        { cmd: 'experience', desc: 'Explore my professional journey' },
                        { cmd: 'goals', desc: 'Discover my aspirations' },
                        { cmd: 'clear', desc: 'Clear the terminal' },
                        { cmd: 'sudo rm -rf /*', desc: 'Try at your own risk!' },
                    ].map(({ cmd, desc }) => (
                        <div key={cmd} className="flex">
                            <span className="text-[#f0883e] w-24">{cmd}</span>
                            <span className="text-[#8b949e]">- {desc}</span>
                        </div>
                    ))}
                </div>
            );
            case 'skills':
    return (
        <div className="space-y-2">
            <div>
                <span className="text-[#f0883e]">Programming Languages:</span>
                <p className="text-[#8b949e]">Python, C++, C, Java</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Machine Learning:</span>
                <p className="text-[#8b949e]">Supervised and Unsupervised Learning</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Deep Learning and Neural Networks:</span>
                <p className="text-[#8b949e]">ANN, CNN, RNN, LSTM</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Generative AI:</span>
                <p className="text-[#8b949e]">LLMs, NLP, RAGs, Autonomous Agents</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Frontend Development:</span>
                <p className="text-[#8b949e]">HTML, CSS, JavaScript, SQL</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Cloud Services:</span>
                <p className="text-[#8b949e]">AWS, Docker, Kubernetes, Firebase, Google Cloud Platform, Terraform, Jenkins</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Frameworks and Tools:</span>
                <p className="text-[#8b949e]">FastAPI, Flask, Langchain, LlamaIndex, Mistral, Langgraph, PowerBI, MS Excel</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Databases:</span>
                <p className="text-[#8b949e]">MySQL, SQL</p>
            </div>
            <div>
                <span className="text-[#f0883e]">Version Control:</span>
                <p className="text-[#8b949e]">Git, GitHub</p>
            </div>
        </div>
    );
        case 'about':
            return (
                <div className="space-y-2">
                    <p>Hey there! Thanks for checking out my little corner of the internet.</p> 
                    <p>I’m <span className="text-[#f0883e]">Prakhar Agrawal</span>, a tech enthusiast who, let’s be honest, always dreamt of starting my own business but somehow ended up in the 9-to-5 grind—because, well, trend hi chal raha hai! But hey, if you ever want to bounce around some startup ideas (just ideas, not investment pitches, okay?), I’m your person.</p> 
                    <p>Professionally, I’m all about <span className="text-[#f0883e]">AI, ML, and cloud shenanigans</span>. Whether it’s tinkering with Generative AI, winning Kaggle contests, or solving real-world problems with code, I’m here for it. My motto? <span className="text-[#f0883e]">“Break it to make it better.”</span></p>
                     <p>Oh, and sarcasm is my second language, so if you enjoy a good laugh (or eye roll), we’ll get along just fine.</p> 
                      <p>So, whether it’s tech talk, music recs, or startup banter, feel free to reach out. Who knows? We might just solve the world’s next big problem—or at least have a good laugh trying!</p>
                </div>
            );
        case 'socials':
            return (
                <div className="space-y-2">
                    <p>Social life? What's that? I live in a basement :) ...</p>
                    <p>Thanks for your interest though! Here's where you can find me online:</p>
                    <div className="flex flex-col space-y-1">
                        <div className="flex">
                            <span className="text-[#f0883e] w-24">GitHub</span>
                            <a href="https://github.com/Rishi-1241" target="_blank" rel="noopener noreferrer" className="text-[#58a6ff]">
                                Rishi-1241
                            </a>
                        </div>
                        <div className="flex">
                            <span className="text-[#f0883e] w-24">LinkedIn</span>
                            <a href="https://www.linkedin.com/in/prakhar-agrawal-82a8b0257/" target="_blank" rel="noopener noreferrer" className="text-[#58a6ff]">
                                Prakhar Agrawral
                            </a>
                        </div>
                        <div className="flex">
                            <span className="text-[#f0883e] w-24">Email</span>
                            <a href="mailto:shubh622005@gmail.com" className="text-[#58a6ff]">
                                rishiagrawal117@gmail.com
                            </a>
                        </div>
                    </div>
                    <p>Feel free to reach out! I'm most responsive on LinkedIn.</p>
                </div>
            );
            case 'projects':
    return (
        <div className="space-y-4">
            {[
                {
                    name: 'UltraGPT',
                    desc: 'A complete backend to train our LLM with few prompts, create custom AI bots using your data and use case, retaining bot memory, and integrating user database and subscription model.',
                    github: 'https://github.com/Rishi-1241/ultragpt',
                },
                {
                    name: 'RAG Bot',
                    desc: 'A multimodal advanced RAG chatbot, handling photos, videos, and texts for two companies (Ahilio and LawSikho) and integrated to WhatsApp.',
                    link: 'https://github.com/Rishi-1241/RagBot',
                },
                {
                    name: 'OmniAI',
                    desc: 'Developed an AI assistant with 5 key capabilities including document summarization, video-to-text conversion, news aggregation, code generation, and Figma-to-HTML/CSS conversion.',
                    link: 'https://youtu.be/p9v2ImkLqiU',
                },
                {
                    name: 'Python-CLI-SSH',
                    desc: 'A command-line interface (CLI) written in Python to connect to a cloud server over SSH and perform tasks based on a provided keyword using Flask, MySQL, and Paramiko.',
                    link: 'https://github.com/Rishi-1241/python-cli-mysql/tree/main/flask-mysql-cloudserver-analytics-cli',
                },
                {
                    name: 'Multiclass Vehicle Classification',
                    desc: 'Built a machine learning model for vehicle classification using silhouette features and achieved 98.9% accuracy with Support Vector Machine (SVM).',
                    link: 'https://github.com/Rishi-1241/Vehicle-Classification',
                },
                    ].map((project, index) => (
                        <div key={index} className="border border-[#30363d] rounded-md p-4 bg-[#0d1117]">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h3 className="text-[#f0883e] text-lg font-semibold">{project.name}</h3>
                                    <p className="text-[#8b949e] mt-1">{project.desc}</p>
                                </div>
                                <div className="flex space-x-2">
                                    
                                    {project.github && (
                                        <a
                                            href={project.github}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-[#58a6ff] hover:text-[#58a6ff] transition-colors"
                                        >
                                            <FaGithub size={24} />
                                        </a>
                                    )}
                                    
                                    {project.link && (
                                        <a
                                            href={project.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-[#58a6ff] hover:text-[#58a6ff] transition-colors"
                                        >
                                            <FaGlobe size={24} />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        case 'clear':
            setCommand([]);
            return '';
        case 'resume':
            window.open('https://drive.google.com/file/d/14H-qgCS50CxHx14JJJu4Bb5cDpuE2Yi9/view?usp=sharing', '_blank');
            return 'Opening resume in a new tab...';
            case 'experience':
                return (
                    <div className="space-y-6">
                        {[
                            {
                                company: 'Deepsolv',
                                role: 'Generative AI Engineer',
                                location: 'Remote',
                                date: '15 May 2023 - 15 July',
                                description: 'Engineered advanced Generative AI solutions, including RAG chatbots and autonomous agents, improving user engagement, satisfaction, and system efficiency across multiple platforms.',
                                achievements: [
                                    'Developed 2 RAG chatbots and autonomous conversational agents for a yacht booking company and a law courses provider, improving client interactions by 20%.',
                                    'Leveraged Langchain, Langgraph, and LlamaIndex to enhance conversational AI capabilities, leading to a 15% increase in customer satisfaction.',
                                    'Deployed models on WhatsApp, successfully handling over 1,000 user interactions and managing databases via Firestore.',
                                    'Performed hyperparameter tuning, boosting AI response accuracy by 20%, and collaborated with cross-functional teams to ensure smooth project delivery.',
                                ],
                                icon: <FaRobot className="text-[#f0883e] text-4xl" />,
                            },
                        ].map((job, index) => (
                            <div
                                key={index}
                                className="relative bg-gradient-to-r from-[#1a1b26] to-[#24283b] rounded-lg p-6 shadow-lg border border-[#30363d] overflow-hidden"
                            >
                                <div className="absolute top-0 right-0 w-24 h-24 bg-gradient-to-br from-[#f0883e] to-[#ff4d4d] opacity-10 rounded-bl-full"></div>
                                <div className="flex items-start space-x-4">
                                    <div className="flex-shrink-0">{job.icon}</div>
                                    <div className="flex-grow">
                                        <div className="flex justify-between items-start mb-2">
                                            <h3 className="text-[#f0883e] text-xl font-bold">{job.company}</h3>
                                            <span className="text-[#8b949e] text-sm">{job.date}</span>
                                        </div>
                                        <p className="text-[#58a6ff] font-semibold mb-1">{job.role}</p>
                                        {job.location && <p className="text-[#8b949e] text-sm mb-2">{job.location}</p>}
                                        {job.description && <p className="text-[#8b949e] mb-4">{job.description}</p>}
                                        <div className="space-y-2">
                                            <p className="text-[#f0883e] font-semibold">🔥 Key Achievements:</p>
                                            <ul className="list-none space-y-2">
                                                {job.achievements.map((achievement, i) => (
                                                    <li key={i} className="flex items-start">
                                                        <span className="text-[#f0883e] mr-2">▹</span>
                                                        <span className="text-[#8b949e]">{achievement}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#f0883e] to-[#ff4d4d]"></div>
                            </div>
                        ))}
                    </div>
                );
            
                case 'goals':
                    return (
                        <div className="space-y-4">
                            <p className="text-[#f0883e] font-bold text-xl">
                                The future? Oh, it’s an unsolved Rubik’s Cube, but I’m twisting the right corners with these goals:
                            </p>
                            <ul className="list-disc list-inside space-y-3 text-[#8b949e]">
                                <li>
                                    Currently architecting <span className="text-[#f0883e] font-semibold">Morsel</span>—a placement platform that’s more than 
                                    just a job board; it’s a career launcher.{' '}
                                    <span className="text-[#58a6ff]">
                                        (HR pros, why swipe right on talent matches when you can connect with me instead?)
                                    </span>
                                </li>
                                <li>
                                    <span className="text-[#f0883e] font-semibold">"Why follow the crowd when you can create one?"</span> 
                                    My passion lies in startups—chaos, coffee, and creating something revolutionary from scratch!
                                </li>
                                <li>
                                    Being impactful isn’t just about saving the planet (though that’s on my to-do list), it’s about leaving a 
                                    mark so unique even <span className="text-[#f0883e] font-semibold">Google</span> can’t predict it.
                                </li>
                                <li>
                                    <span className="text-[#f0883e] font-semibold">AI today, space colonies tomorrow, hydroponic jungles next weekend</span>—
                                    I’m a sucker for fields where imagination meets reality.
                                </li>
                                <li>
                                    And of course, life isn’t just a race; it’s a marathon with snack breaks, meme-making moments, and a relentless 
                                    pursuit of things that truly excite me.
                                </li>
                            </ul>
                            <p className="text-[#58a6ff] font-semibold text-lg">
                                The goal isn’t just to find the path; it’s to pave one so cool that others want to follow it!
                            </p>
                        </div>
                    );
                
        default:
            return (
                <div>
                    <span className="text-[#f97583]">Command not found.</span>
                    <br />
                    Type <span className="text-[#f0883e]">help</span> to see available commands.
                </div>
            );
    }
};